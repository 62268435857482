
import {
    defineComponent, Ref, ref, watch, computed
} from 'vue';
import { personnel, access, community } from '@/data/index';
import propertyBreadCrumb from '@/components/view/pm/bread-crumb';
import { propertyBaseRouter, propertyOldCommunitySubRouter } from '@/router/data';
import { BreadCrumbData, TempKeyInfoData, ListData } from '@/views/pm/personnel/temp-key-info/all.type';
import { list, ListHeaderItem } from '@/components/common/list';
import doorLogs from '@/components/view/pm/personnel-temp-key-info-dialog';
import { ChangeWordList } from '@/methods/setText';
import { limitErrorImg } from '@/methods/basicFun';
import { getTempKeysBasic, getProjectData } from './util';

export default defineComponent({
    props: {
        ID: {
            type: String,
            required: true
        }
    },
    components: {
        propertyBreadCrumb,
        list,
        doorLogs
    },
    setup(props) {
        const imgItem = ref<Array<any>>([]);
        const setAllImg = (el: any) => {
            if (el) {
                limitErrorImg(el);
                imgItem.value.push(el);
            }
        };

        // 面包屑
        const breadCrumb: Ref<Array<BreadCrumbData>> = ref([
            {
                label: WordList.DarshbordTmp,
                path: `/${propertyBaseRouter}/${propertyOldCommunitySubRouter.oldTempKeys}`
            }, {
                label: ''
            }
        ]);

        // info数据处理
        const tempKeyInfoData: Ref<TempKeyInfoData | {}> = ref({});
        const tempKeyBaseData: Ref<Array<ListData>> = ref([{
            name: '',
            label: '',
            value: ''
        }]);
        const headers = ref<ListHeaderItem[]>([{
            name: 'Location',
            label: WordList.TabelPersonDeviceInHtmlLocation
        }, {
            name: 'UnitName',
            label: WordList.DeviceDetailDetailUnitName
        }, {
            name: 'MAC',
            label: WordList.TabelPersonDeviceInHtmlMAC
        }, {
            name: 'Relay',
            label: WordList.ProperAllTextRelay,
            type: 'customize'
        }]);

        const isShowQrCode = ref(false);
        personnel.getPersonnelTempKeysInfo({ ID: props.ID }, (res: TempKeyInfoData) => {
            tempKeyInfoData.value = res;
            watch(computed(() => community.IsEncryptPin.value), (val) => {
                if (val === '0') {
                    isShowQrCode.value = true;
                }
            }, {
                immediate: true
            });
            breadCrumb.value[1].label = res.TmpKey;
            tempKeyBaseData.value = getTempKeysBasic(res, 'community');
        });

        // SchedulerType = 2(weekly)的情况
        const getWeekName = (dateFlag: string) => {
            const date: Array<string> = dateFlag.split(';');
            const tmpDate: Array<string> = [];
            date.forEach((item: string, index: number) => {
                tmpDate.push(access.weekList[Number(date[index])]);
            });
            return tmpDate.join(',');
        };

        // list数据绑定
        const updateToList = ref(0);
        watch(
            () => tempKeyInfoData.value,
            () => {
                updateToList.value += 1;
            }
        );

        const isShowDoorLogs = ref(false);
        return {
            breadCrumb,
            tempKeyInfoData,
            tempKeyBaseData,
            access,
            getWeekName,
            headers,
            updateToList,
            isShowDoorLogs,
            isShowQrCode,
            setAllImg
        };
    }
});
