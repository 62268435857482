import { TempKeyBasicData } from '@/views/pm/personnel/temp-key-info/all.type';
import { filterEmptyCellValue, ListHeaderItem } from '@/components/common/list';

export default null;
const getTempKeysBasic = (data: TempKeyBasicData, projectType: ProjectType) => {
    let tempKeysBasicList: Array<{
        name: keyof TempKeyBasicData | 'Apt';
        label: string;
        value: string;
    }> = [
        {
            name: 'UnitName',
            label: WordList.DeviceDetailDetailUnitName,
            value: ''
        }, {
            name: 'Apt',
            label: WordList.RDeviceSearchLabelRoomName,
            value: ''
        }, {
            name: 'Description',
            label: WordList.TabelPersonUserInHtmlName,
            value: ''
        }, {
            name: 'IDNumber',
            label: WordList.ProperAllTextIdNumber,
            value: ''
        }, {
            name: 'CreatorName',
            label: WordList.ProperAllTextCreatedBy,
            value: ''
        }
    ];
    const officeBasicList: Array<{
        name: keyof TempKeyBasicData | 'Apt';
        label: string;
        value: string;
    }> = [
        {
            name: 'UnitName',
            label: WordList.ProperAllTextDepartment,
            value: ''
        }, {
            name: 'People',
            label: WordList.ProperAllTextPeople,
            value: ''
        }, {
            name: 'Description',
            label: WordList.TabelPersonUserInHtmlName,
            value: ''
        }, {
            name: 'IDNumber',
            label: WordList.ProperAllTextIdNumber,
            value: ''
        }, {
            name: 'CreatorName',
            label: WordList.ProperAllTextCreatedBy,
            value: ''
        }
    ];
    if (projectType === 'office') {
        tempKeysBasicList = officeBasicList;
    }
    tempKeysBasicList.forEach((param, index) => {
        if (param.name !== 'Apt') {
            tempKeysBasicList[index].value = filterEmptyCellValue(data[param.name]).toString();
        } else {
            tempKeysBasicList[index].value = data.RoomName ? `${data.RoomName} ${data.RoomNumber}` : '--';
        }
    });

    return tempKeysBasicList;
};

type ProjectData = {
    [key in ProjectType]: {
        header: Array<ListHeaderItem>;
    }
};
const getProjectData = () => {
    const communityHeader: Array<ListHeaderItem> = [{
        name: 'Location',
        label: WordList.TabelPersonDeviceInHtmlLocation
    }, {
        name: 'UnitName',
        label: WordList.DeviceDetailDetailUnitName
    }, {
        name: 'MAC',
        label: WordList.TabelPersonDeviceInHtmlMAC
    }, {
        name: 'Relay',
        label: WordList.ProperAllTextRelay,
        type: 'customize'
    }];
    const officeHeader: Array<ListHeaderItem> = [{
        name: 'Location',
        label: WordList.TabelPersonDeviceInHtmlLocation
    }, {
        name: 'UnitName',
        label: WordList.ProperAllTextDepartment
    }, {
        name: 'MAC',
        label: WordList.TabelPersonDeviceInHtmlMAC
    }, {
        name: 'Relay',
        label: WordList.ProperAllTextRelay
    }];
    const projectData: ProjectData = {
        community: {
            header: communityHeader
        },
        office: {
            header: officeHeader
        }
    };
    return projectData;
};

export {
    getTempKeysBasic,
    getProjectData
};